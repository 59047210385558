<template>
  <div style="padding-bottom: 20px; min-height: calc(100vh - 60px)">
    <div v-if="!tableData.length" style=" margin: 10px 0">
      <el-card>
        <div style="text-align: center; color: #999;">暂无申请购买记录</div>
      </el-card>
    </div>
    <div v-else style="margin: 10px 0">
      <el-card  v-for="item in tableData" :key="item.id" style="margin: 10px 0">
        <div style="display: flex">
          <div style="width: 140px"> <img :src="item.products.img" alt="" style="width: 100%; height: 160px; border-radius: 10px"></div>
          <div style="padding-left: 50px; flex: 1">
            <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
              <div><span style="margin-left: 20px">名字：{{ item.products.name }}</span></div>
              <div><span style="margin-left: 20px">价格：{{ item.products.price }}</span></div>
              <div><span style="margin-left: 20px">联系方式：{{ item.phone }}</span></div>
              <div><span style="margin-left: 20px">金额：{{item.totalAmount}} (优惠：{{ item.preferential }}）</span></div>
              <div><span style="margin-left: 20px">配送方式：{{ item.deliveryMethod }}</span>
                <div><span style="margin-left: 20px">购买数量：{{ item.number }}</span></div>
                <div v-if="item.deliveryMethod==='邮寄'" style="float: right">
                  <span style="margin-left: 10px">收件人：{{ item.recipient }}</span>
                  <span style="margin-left: 10px">地址：{{ item.address }}</span>
                </div>
              </div>
              <div><span style="margin-left: 20px">联系方式：{{ item.phone }}</span></div>
              <div><span style="margin-left: 20px">付款方式：{{ item.paymentMethod }}</span></div>
              <div><span style="margin-left: 20px">状态：{{ item.opStatus }}</span></div>

              <div><span style="margin-left: 20px">商品数量：{{ item.products.pnumber }}</span></div>
              <div><b style="margin-right: 10px">商品介绍：</b>{{ item.products.description }}</div>
            </div>
          </div>
        </div>
      </el-card>
      <div style="margin: 10px 0; padding: 10px; background-color: #fff">
        <el-pagination
            size="mid"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "FrontHome",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      dialogFormVisible: false,
      form: {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/productsBuy/my",
          {
            params: {
              pageNum: this.pageNum,
              pageSize: this.pageSize
            }
          }).then(res => {
            console.log(res);
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
  }
}
</script>

<style>

</style>
